import React, {useState} from 'react'
let i = 0;


export default function Componente1() {

    // Estado para almacenar el valor actual del input
    const [inputValue, setInputValue] = useState("");
    
    // Estado para almacenar la lista de valores guardados
    const [savedValues, setSavedValues] = useState([]);
  
    // Función que se ejecuta al cambiar el input
    const handleInputChange = (e) => {
    setInputValue(e.target.value);
    };

    // Función que se ejecuta al hacer clic en el botón
    const buttonOnClick = () => {

      if(i<23){
        if (inputValue.length > 0){
          // Agregar el valor actual del input a la lista de valores guardados
          setSavedValues([...savedValues, inputValue]);
          // Limpiar el input después de guardar el valor
          setInputValue("");
        };
        i++;
      };
      
    };


  return (
      <body>

        <header>

          <div id="header_izq">
            <h2>NOTAS:</h2>
          </div>
  
          <div id="entrada">
            <input type="text" maxlength="50" placeholder="Añade una nota" value={inputValue} onChange={handleInputChange} />
            <button onClick={buttonOnClick}>AÑADIR</button>
          </div>

          <div id="header_der"></div>

        </header>
                
        <section id="contenedor_zona_1">

          <div id="izq" ></div>

          <div id="salida">
          
            <ol>
              {savedValues.map((value, index) => (
                <li key={index}>{value}</li>
              ))}
            </ol>

          </div>
          <div id="der"></div>

        </section>

        <footer>

          <p>WEB DESARROLLADA CON REACT</p>

        </footer>

      </body>
  )
}