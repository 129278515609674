import './App.css';
import Componente1 from './componentes.jsx';

function App() {
  return (

    <Componente1 />

  );
}

export default App;
